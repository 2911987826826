import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  VStack,
  HStack,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Skeleton,
  IconButton,
  Tooltip,
  Text,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { StarIcon, EditIcon } from '@chakra-ui/icons';
import axios from 'axios';
import Cookies from 'js-cookie';

import ClientHeader from './components/ClientHeader';
import ClientInfoCards from './components/ClientInfoCards';
import EmailPanel from './components/EmailPanel';
import CallPanel from './components/CallPanel';
import ChatPanel from './components/ChatPanel';
import OverviewPanel from './components/OverviewPanel';
import InsightsPanel from './components/InsightsPanel';

import { useClientData } from './hooks/useClientData';
import { useCorrespondence } from './hooks/useCorrespondence';
import { useChat } from './hooks/useChat';
import { useUser } from '../../../contexts/UserContext';

const sfUrl = 'https://amazing-einstein-73385-dev-ed.develop.lightning.force.com/lightning/r/';
const wbUrl = 'https://app.crmworkspace.com/contacts/';
const rtUrl = 'https://crm.redtailtechnology.com/contacts/';

const ClientPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get('tab') || 0;
  const [tabIndex, setTabIndex] = useState(Number(defaultTab));
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const [isFavorite, setIsFavorite] = useState(false);
  const [insights, setInsights] = useState([]);
  const { user } = useUser();
  const isBetaFeaturesEnabled = user?.betaFeaturesEnabled || false;
  const isAiInsightsEnabled = user?.settings?.aiInsightsEnabled || false;
  const [meetingPrepData, setMeetingPrepData] = useState({});
  const [isLoadingMeetingPrep, setIsLoadingMeetingPrep] = useState(false);

  const { 
    client, 
    loading,
    fetchClientData 
  } = useClientData(id, serverUrl, toast);

  const {
    emailHistory,
    callHistory,
    showArchivedCorrespondence,
    loadingArchivedActions,
    handleArchiveToggle,
    handleActionUpdate,
    handleViewEmail,
    totalEmails,
    totalCalls,
    emailPage,
    callPage,
    isLoadingMoreEmails,
    isLoadingMoreCalls,
    pendingEmailCount,
    pendingCallCount,
    isLoadingArchived,
    fetchEmailHistory,
    fetchCallHistory
  } = useCorrespondence(id, serverUrl, client, toast);

  const {
    chatHistory,
    inputValue,
    loadingChat,
    handleAskQuestion,
    setInputValue
  } = useChat(id, serverUrl, toast);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const openInCrm = (id, crmSource) => {
    if (crmSource === 'Salesforce') {
      window.open(`${sfUrl}${id}/view`, '_blank');
    }
    if (crmSource === 'Wealthbox') {
      window.open(`${wbUrl}${id}`, '_blank');
    }
    if (crmSource === 'RedTail') {
      window.open(`${rtUrl}${id}/`, '_blank');
    }
  };

  const checkFavoriteStatus = async () => {
    try {
      const response = await fetch(`${serverUrl}/api/user-profile`, {
        credentials: 'include'
      });
      const data = await response.json();
      setIsFavorite(data.favoriteClients?.some(client => client.id === id) || false);
    } catch (error) {
      console.error('Error checking favorite status:', error);
    }
  };

  const handleFavoriteToggle = async () => {
    try {
      const method = isFavorite ? 'DELETE' : 'POST';
      const response = await fetch(`${serverUrl}/api/clients/${id}/favorite`, {
        method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to update favorite status');
      }

      setIsFavorite(!isFavorite);
      toast({
        title: isFavorite ? 'Client removed from favorites' : 'Client added to favorites',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating favorite status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update favorite status',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchInsights = async () => {
    try {
      const response = await axios.get(`${serverUrl}/api/clients/${id}/insights`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      setInsights(response.data.insights);
    } catch (error) {
      console.error('Error fetching insights:', error);
    }
  };

  const fetchMeetingPrepData = async () => {
    setIsLoadingMeetingPrep(true);
    try {
      const response = await axios.get(`${serverUrl}/api/meeting-prep/${id}`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      setMeetingPrepData(response.data);
    } catch (error) {
      console.error('Error fetching meeting prep data:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch meeting prep data',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingMeetingPrep(false);
    }
  };

  useEffect(() => {
    if (id) {
      checkFavoriteStatus();
      fetchInsights();
    }
  }, [id]);

  useEffect(() => {
    if (tabIndex === 4) {
      fetchMeetingPrepData();
    }
  }, [tabIndex, id]);

  const renderMeetingPrepData = () => {
    if (!meetingPrepData?.meetingPrepData) {
        return <Text>Loading...</Text>;
    }

    const data = meetingPrepData.meetingPrepData;

    return (
        <Box>
            <Box mb={4}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>Recent Meetings</Text>
                {data.recent_meetings?.length > 0 ? (
                    <UnorderedList>
                        {data.recent_meetings.map((meeting, index) => (
                            <ListItem key={index}>{meeting}</ListItem>
                        ))}
                    </UnorderedList>
                ) : (
                    <Text>No recent meetings recorded.</Text>
                )}
            </Box>

            <Box mb={4}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>Upcoming Events</Text>
                {data.upcoming_events?.length > 0 ? (
                    <UnorderedList>
                        {data.upcoming_events.map((event, index) => (
                            <ListItem key={index}>{event}</ListItem>
                        ))}
                    </UnorderedList>
                ) : (
                    <Text>No upcoming events recorded.</Text>
                )}
            </Box>

            <Box mb={4}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>Conversation Starters</Text>
                {data.conversation_starters?.length > 0 ? (
                    <UnorderedList>
                        {data.conversation_starters.map((starter, index) => (
                            <ListItem key={index}>{starter}</ListItem>
                        ))}
                    </UnorderedList>
                ) : (
                    <Text>No conversation starters available.</Text>
                )}
            </Box>

            <Box mb={4}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>Household</Text>
                {data.household?.length > 0 ? (
                    <UnorderedList>
                        {data.household.map((member, index) => (
                            <ListItem key={index}>{member}</ListItem>
                        ))}
                    </UnorderedList>
                ) : (
                    <Text>No household information available.</Text>
                )}
            </Box>

            <Box mb={4}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>Client Goals</Text>
                {data.client_goals?.length > 0 ? (
                    <UnorderedList>
                        {data.client_goals.map((goal, index) => (
                            <ListItem key={index}>{goal}</ListItem>
                        ))}
                    </UnorderedList>
                ) : (
                    <Text>No client goals recorded.</Text>
                )}
            </Box>

            <Box mb={4} textAlign="right">
                {/* //Uncomment this for a dev refresh button for prompt engineering. */}
                <Button 
                    colorScheme="blue" 
                    mr={3}
                    onClick={() => fetchMeetingPrepData()}
                    isLoading={isLoadingMeetingPrep}
                >
                    Refresh
                </Button>
                <Button 
                    colorScheme="black" 
                    variant="link" 
                    onClick={() => window.location.href = `${serverUrl}/settings`}
                >
                    Meeting Prep Settings
                </Button>
            </Box>
        </Box>
    );
  };

  return (
    <Box p={8} bg="white">
      {loading ? (
        <VStack spacing={4} align="stretch">
          <Skeleton height="40px" />
          <Skeleton height="200px" />
          <Skeleton height="400px" />
        </VStack>
      ) : (
        <VStack spacing={8} align="stretch">
          <HStack justify="space-between" mb={4}>
            <Button onClick={() => navigate('/clients')} colorScheme="blue" variant="outline" size="sm">
              Back
            </Button>
            <Tooltip 
              label={isFavorite ? "Remove from favorites" : "Add to favorites"}
              placement="top"
              hasArrow
            >
              <IconButton
                icon={<StarIcon />}
                aria-label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                colorScheme={isFavorite ? 'yellow' : 'gray'}
                onClick={handleFavoriteToggle}
                size="sm"
              />
            </Tooltip>
          </HStack>

          <ClientHeader client={client} onOpenInCrm={openInCrm} />

          <Tabs colorScheme="blue" index={tabIndex} onChange={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>Overview</Tab>
              <Tab position="relative">
                Emails
                {!showArchivedCorrespondence && pendingEmailCount > 0 && (
                  <Box
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    bg="red.500"
                    color="white"
                    borderRadius="full"
                    minW="20px"
                    height="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="xs"
                    fontWeight="bold"
                    px={1}
                  >
                    {pendingEmailCount}
                  </Box>
                )}
              </Tab>
              <Tab position="relative">
                Calls
                {!showArchivedCorrespondence && pendingCallCount > 0 && (
                  <Box
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    bg="red.500"
                    color="white"
                    borderRadius="full"
                    minW="20px"
                    height="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="xs"
                    fontWeight="bold"
                    px={1}
                  >
                    {pendingCallCount}
                  </Box>
                )}
              </Tab>
              <Tab>Chat</Tab>
              {(isBetaFeaturesEnabled && isAiInsightsEnabled) && <Tab>Insights</Tab>}
              <Tab>Meeting Prep</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <OverviewPanel client={client} />
              </TabPanel>
              
              <TabPanel>
                <EmailPanel 
                  emailHistory={emailHistory}
                  showArchivedCorrespondence={showArchivedCorrespondence}
                  isLoadingArchived={isLoadingArchived}
                  handleArchiveToggle={handleArchiveToggle}
                  loadingArchivedActions={loadingArchivedActions}
                  client={client}
                  handleActionUpdate={handleActionUpdate}
                  handleViewEmail={handleViewEmail}
                  totalEmails={totalEmails}
                  isLoadingMoreEmails={isLoadingMoreEmails}
                  fetchEmailHistory={fetchEmailHistory}
                  emailPage={emailPage}
                  pendingEmailCount={pendingEmailCount}
                />
              </TabPanel>

              <TabPanel>
                <CallPanel 
                  callHistory={callHistory}
                  showArchivedCorrespondence={showArchivedCorrespondence}
                  isLoadingArchived={isLoadingArchived}
                  handleArchiveToggle={handleArchiveToggle}
                  loadingArchivedActions={loadingArchivedActions}
                  client={client}
                  handleActionUpdate={handleActionUpdate}
                  totalCalls={totalCalls}
                  isLoadingMoreCalls={isLoadingMoreCalls}
                  fetchCallHistory={fetchCallHistory}
                  callPage={callPage}
                  pendingCallCount={pendingCallCount}
                />
              </TabPanel>

              <TabPanel>
                <ChatPanel 
                  chatHistory={chatHistory}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleAskQuestion={handleAskQuestion}
                  loadingChat={loadingChat}
                  handleViewEmail={handleViewEmail}
                  formatDate={formatDate}
                />
              </TabPanel>

              {(isBetaFeaturesEnabled && isAiInsightsEnabled) && (
                <TabPanel>
                  <InsightsPanel 
                    insights={insights}
                    onViewSource={(sourceType, sourceId) => {
                      const tabIndex = sourceType === 'email' ? 1 : 2;
                      setTabIndex(tabIndex);
                      
                      setTimeout(() => {
                        const element = document.getElementById(`${sourceType}-${sourceId}`);
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          element.style.backgroundColor = 'blue.50';
                          setTimeout(() => {
                            element.style.backgroundColor = '';
                          }, 2000);
                        }
                      }, 100);
                    }}
                  />
                </TabPanel>
              )}

              <TabPanel>
                <Box p={4}>
                  {renderMeetingPrepData()}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      )}
    </Box>
  );
};

export default ClientPage; 