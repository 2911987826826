import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './components/pages/Home/Home';
import Clients from './components/pages/Clients/Clients';
import Meetings from './components/pages/Meetings/Meetings';
import Settings from './components/pages/Settings/Settings';
import Integrations from './components/pages/Integrations/Integrations';
import Correspondence from './components/pages/Clients/Correspondence';
import LiveMeeting from './components/pages/Meetings/LiveMeeting';
import LiveMeetingTest from './components/pages/Meetings/LiveMeetingTest';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Salesforce from './components/pages/Salesforce';
import RedtailConnect from './components/pages/RedtailConnect/RedtailConnect';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import './App.css';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import { useRef } from 'react';
import FollowUpPage from './components/FollowUpPage';
import './styles/quill.css';
import { UserProvider, useUser } from './contexts/UserContext';
import { OrganizationClientsProvider } from './contexts/OrganizationClientsContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { OrganizationUsersProvider } from './contexts/OrganizationUsersContext';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import Upload from './components/pages/Meetings/Upload';
import ClientPage from './components/pages/Clients/ClientPage';
import AdminApprovalOverlay from './components/AdminApprovalOverlay';
import AddClient from './components/pages/Clients/AddClient';
import Checkout from './components/pages/Checkout/Checkout';
import CheckoutSimple from './components/pages/Checkout/CheckoutSimple';
import EditClient from './components/pages/Clients/EditClient';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const isAuthRoute = location.pathname === '/login' || 
                     location.pathname === '/signup' || 
                     location.pathname === '/forgot-password' || 
                     location.pathname.includes('/reset-password/');
  
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/checkout" element={<CheckoutSimple />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <UserProvider>
              <OrganizationClientsProvider>
                <OrganizationUsersProvider>
                  <NotificationProvider>
                    <MainLayout />
                  </NotificationProvider>
                </OrganizationUsersProvider>
              </OrganizationClientsProvider>
            </UserProvider>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

function MainLayout() {
  const scrollBoxRef = useRef(null);
  const location = useLocation();
  const { user, loading } = useUser();
  const isAuthRoute = location.pathname === '/login' || 
                     location.pathname === '/signup' || 
                     location.pathname === '/forgot-password' || 
                     location.pathname.includes('/forgot-password/');

  const showAdminApprovalOverlay = user && user.subscriptionActivated === null && user.role !== 'admin';

  return (
    <Flex className="app" height="100vh" overflow="hidden">
      {!isAuthRoute && <Sidebar />}
      <Box
        position="relative"
        ref={scrollBoxRef}
        flex="1"
        width="100%"
        bg="#F5F5F5"
        pt={!isAuthRoute && !location.pathname.includes('/transcripts/') ? 8 : 0}
        pl={!isAuthRoute && !location.pathname.includes('/transcripts/') ? 8 : 0}
        pr={!isAuthRoute && !location.pathname.includes('/transcripts/') ? 8 : 0}
        overflowY="auto"
        transition="all 0.3s ease"
      >
        {loading ? (
          <Flex 
            height="100%" 
            width="100%" 
            justifyContent="center" 
            alignItems="center"
          >
            <Spinner 
              size="xl" 
              color="#00417D" 
              thickness="4px"
            />
          </Flex>
        ) : (
          <>
            {showAdminApprovalOverlay && (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={9999}
              >
                <AdminApprovalOverlay />
              </Box>
            )}
            <Routes>
              <Route path="/" element={<Home scrollRef={scrollBoxRef} />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/:id" element={<ClientPage />} />
              <Route path="/meetings" element={<Meetings />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/correspondence" element={<Correspondence />} />
              <Route path="/salesforce" element={<Salesforce />} />
              <Route path="/transcripts/:id" element={<Correspondence />} />
              <Route path="/correspondence/email/:id" element={<Correspondence />} />
              <Route path="/redtail/connect" element={<RedtailConnect />} />
              <Route path="/live-meeting" element={<LiveMeeting />} />
              <Route path="/live-meeting-test" element={<LiveMeetingTest />} />
              <Route path="/follow-up/:type/:id" element={<FollowUpPage />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/clients/new" element={<AddClient />} />
              <Route path="/clients/:id/edit" element={<EditClient />} />
            </Routes>
          </>
        )}
      </Box>
    </Flex>
  );
}

export default App;