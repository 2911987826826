import React, { useState, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    Text,
    VStack,
    Progress,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Select,
    useToast,
    Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';

const serverUrl = process.env.REACT_APP_API_URL;
const ACCEPTED_FILE_TYPES = ['audio/mpeg', 'audio/mp4', 'audio/x-m4a', 'audio/wav', 'video/mp4'];
const ACCEPTED_FILE_EXTENSIONS = ['.mp3', '.mp4', '.m4a', '.wav'];
const MAX_FILE_SIZE_MB = 200;

function Upload() {
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [meetingName, setMeetingName] = useState('');
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedClient, setSelectedClient] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const toast = useToast();
    const { clients } = useOrganizationClients();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles && droppedFiles.length > 0) {
            const selectedFile = droppedFiles[0];
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            console.log(`File size: ${fileSizeMB} MB`);
            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                toast({
                    title: 'File Too Large',
                    description: `Please upload a file smaller than ${MAX_FILE_SIZE_MB} MB.`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            if (ACCEPTED_FILE_TYPES.includes(selectedFile.type)) {
                setFile(selectedFile);
                setShowSaveModal(true);
                e.dataTransfer.clearData();
            } else {
                toast({
                    title: 'Invalid File Type',
                    description: 'Please upload an MP3, M4A, WAV, or MP4 file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            console.log(`File size: ${fileSizeMB} MB`);
            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                toast({
                    title: 'File Too Large',
                    description: `Please upload a file smaller than ${MAX_FILE_SIZE_MB} MB.`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            if (ACCEPTED_FILE_TYPES.includes(selectedFile.type)) {
                setFile(selectedFile);
                setShowSaveModal(true);
            } else {
                toast({
                    title: 'Invalid File Type',
                    description: 'Please upload an MP3, M4A, WAV, or MP4 file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleClientChange = (e) => {
        const value = e.target.value;
        setSelectedClientId(value === 'untitled' ? undefined : value);

        const client = clients.find(client => client.id === value);
        setSelectedClient(client ? `${client.firstName} ${client.lastName}` : '');
    };

    const handleUpload = async () => {
        if (!file) return;

        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('meetingName', meetingName || `Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`);
        formData.append('selectedClientId', selectedClientId || '');
        formData.append('clientName', selectedClient);

        // Log form data contents
        console.log('Form Data being sent:');
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
        console.log('Selected Client ID:', selectedClientId);
        console.log('Selected Client Name:', selectedClient);

        try {
            const response = await axios.post(`${serverUrl}/api/upload-audio`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                },
            });

            toast({
                title: 'Upload Successful',
                description: 'Your file has been uploaded and will be processed shortly. Please check the home page after a few minutes.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            // Reset states
            setFile(null);
            setShowSaveModal(false);
            setUploadProgress(0);
            setMeetingName('');
            setSelectedClientId(null);
            setSelectedClient('');

            // Optionally navigate to another page after successful upload
            // navigate('/home'); // Uncomment and set desired route

        } catch (error) {
            toast({
                title: 'Upload Failed',
                description: 'There was an error uploading your file. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <Box bg="white" p={8} height="95vh">
            <Heading color="#00417D" pb={4}>Upload Recording</Heading>
            <VStack spacing={6} mt={10}>
                <Text fontSize="xl" textAlign="center">
                    Upload an audio or video file to generate a transcript
                </Text>
                <Text fontSize="md" color="gray.600" textAlign="center">
                    Depending on the size of the file, it will be processed & transcribed in 10-15 minutes.
                    <br />
                    Note that you will need to manually identify the speakers in the transcript.
                    <br />
                    File size limit: 200MB. 
                </Text>

                <Box
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    w="100%"
                    maxW="600px"
                    h="200px"
                    border="2px dashed"
                    borderColor="gray.300"
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="gray.50"
                    cursor="pointer"
                    transition="all 0.2s"
                    _hover={{ borderColor: "blue.400", bg: "blue.50" }}
                    onClick={openFileDialog}
                >
                    <VStack spacing={2}>
                        <Text fontSize="lg">
                            Drag & drop a file here, or click to select
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            Supported formats: MP3, M4A, WAV, MP4
                        </Text>
                    </VStack>
                    <input
                        type="file"
                        accept={ACCEPTED_FILE_EXTENSIONS.join(',')}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                    />
                </Box>

                {isUploading && (
                    <Box w="100%" maxW="600px">
                        <Progress value={uploadProgress} size="sm" colorScheme="blue" />
                        <Text mt={2} textAlign="center">{uploadProgress}% uploaded</Text>
                    </Box>
                )}
            </VStack>

            <Modal isOpen={showSaveModal} onClose={() => setShowSaveModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Save Recording</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <Text fontSize="lg">Give your meeting a name:</Text>
                            <Input
                                placeholder={`Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                                value={meetingName}
                                onChange={(e) => setMeetingName(e.target.value)}
                            />
                            <Text fontSize="lg">Identify Client:</Text>
                            <Select
                                value={selectedClientId || 'untitled'}
                                onChange={handleClientChange}
                                placeholder="Untitled Client"
                            >
                                {clients.map(client => (
                                    <option key={client.id} value={client.id}>
                                        {client.firstName} {client.metadata?.preferredName ? `(${client.metadata?.preferredName})` : ''} {client.lastName}
                                    </option>
                                ))}
                            </Select>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => setShowSaveModal(false)}>
                            Cancel
                        </Button>
                        <Button 
                            colorScheme="blue" 
                            onClick={handleUpload}
                            isLoading={isUploading}
                        >
                            Upload
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default Upload;